import flatpickr from "flatpickr";
import "flatpickr/dist/l10n/pt";

export default function() {

  $(".datepicker").each(function() {
    const min = this.getAttribute("data-min-date");
    const max = this.getAttribute("data-max-date");

    flatpickr(this, {
      dateFormat: "d/m/Y",
      locale: "pt",
      minDate: min,
      maxDate: max
    });
  });

}
