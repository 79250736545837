import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "toggle" ]

  connect() {
    $(this.fieldTarget).on('change', (e) => {
      this.toggle();
    })

    this.toggle();

  }

  toggle() {
    if (this.data.get('show-value').split('|').includes(this.fieldTarget.value)) {
      this.show(this.toggleTarget)
    } else {
      this.hide(this.toggleTarget)
    }
  }

  selected(val) {
    return
  }

  show(el) { el.style.display = 'block'; }
  hide(el) { el.style.display = 'none'; }

}
