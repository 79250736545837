import "bootstrap";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "icon" ]


  toggle() {
    if (this.isPassword) {
      this.setType("text")
      this.removeClass("fa-eye")
      this.addClass("fa-eye-slash")
    } else {
      this.setType("password")
      this.addClass("fa-eye")
      this.removeClass("fa-eye-slash")
    }
  }

  get isPassword() {
    return this.inputTargets[0].type == "password"
  }

  setType(type) {
    this.inputTargets.forEach( e => e.type = type )
  }

  addClass(css) {
    this.iconTargets.forEach( e => e.classList.add(css) )
  }

  removeClass(css) {
    this.iconTargets.forEach( e => e.classList.remove(css) )
  }
}
