import "jquery-mask-plugin";
import "../lib/jquery.maskMoney";

const BRFoneMaskBehavior = function (val) {
  return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
}
const spOptions = {
  onKeyPress: function(val, e, field, options) {
      field.mask(BRFoneMaskBehavior.apply({}, arguments), options);
    }
}

export default () => {

  $("[data-mask]").each(function() {
    if (this.getAttribute("data-mask") == 'br-fone') {
      $(this).mask(BRFoneMaskBehavior, spOptions);
    } else {
      $(this).mask(this.getAttribute("data-mask"), { autoclear: false });
    }

  });

  $(".currency").maskMoney();

}
