import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "uf", "municipio", "orgao", "orgaoSuperior" ]

  static values = {
    municipiosUrl: String,
    orgaosUrl: String,
    orgaosSuperioresUrl: String,
    origem: String,
    preSelectedMunicipio: String,
    preSelectedOrgaoSuperior: String
  }

  connect() {

    $(this.ufTarget).on('change', (e) => {
      if (this.selectedUf) {
        this.buscaMunicipios()
        this.buscaOrgaosSuperiores()
      } else {
        this.clearItens(this.municipioTarget)
      }
    })

    $(this.municipioTarget).on('change', (e) => {
      if (this.selectedMunicipio && this.selectedOrgaoSuperior) {
        this.buscaOrgaos()
      } else {
        this.clearItens(this.orgaoTarget)
      }
    })

    $(this.orgaoSuperiorTarget).on('change', (e) => {
      this.clearItens(this.orgaoTarget)
      if (this.selectedMunicipio && this.selectedOrgaoSuperior) {
        this.buscaOrgaos()
      }
    })

    if (this.selectedUf) {
      this.buscaMunicipios()
      this.buscaOrgaosSuperiores()
    }

    if (this.selectedMunicipio && this.selectedOrgaoSuperior) this.buscaOrgaos()

  }

  buscaMunicipios() {
    this.loading(this.municipioTarget, true)
    $.get({
      url: this.municipiosUrlValue,
      data: { uf: this.selectedUf }
    })
    .done( (response) => this.setItens(response, this.municipioTarget, this.preSelectedMunicipioValue) )
  }

  buscaOrgaosSuperiores() {
    this.loading(this.orgaoSuperiorTarget, true)
    $.get({
      url: this.orgaosSuperioresUrlValue,
      data: { uf: this.selectedUf, origem: this.origemValue }
    })
    .done( (response) => this.setItens(response, this.orgaoSuperiorTarget, this.preSelectedOrgaoSuperiorValue) )
  }

  buscaOrgaos() {
    this.loading(this.orgaoTarget, true)
    $.get({
      url: this.orgaosUrlValue,
      data: { municipio: this.selectedMunicipio, orgao_superior: this.selectedOrgaoSuperior }
    })
    .done( (response) => this.setItens(response, this.orgaoTarget) )
  }

  setItens(itens, target, preSelected) {
    this.loading(target, false)

    if (itens) {
      var options = itens.map( (item) => new Option(item.text, item.id, false, false) )
      target.innerHTML = ""
      $(target).append(options)
      $(target).val(preSelected).trigger('change')
    } else {
      this.clearItens(target)
    }

  }

  clearItens(target) {
    target.innerHTML = "";
    $(target).val(null).trigger('change');
  }

  get selectedOrgaoSuperior() {
    return this.orgaoSuperiorTarget.value
  }

  get selectedMunicipio() {
    return this.municipioTarget.value
  }

  get selectedUf() {
    return this.ufTarget.value
  }

  get preSelectedMunicipio() {
    return this.data.get('municipio-pre-selected')
  }

  get preSelectedOrgaoSuperior() {
    return this.data.get('orgso-pre-selected')
  }


  // get preSelectedOrgao() {
  //   return this.data.get('orgao-selected')
  // }

  loading(target, apply) {
    if (apply) {
      $(target).prop("disabled", true)
    } else {
      $(target).prop("disabled", false)
    }
  }

}
