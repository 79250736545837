const showAjaxMessage = function(msg, type) {
  const alert_type = type === "notice" ? "alert-success" : "alert-danger";

  msg = `\
<div class="alert alert-dismissible ${alert_type}" role="alert">
  ${msg}
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Fechar"></button>
</div>\
`;

  if ($(".modal .flash-messages:visible").length) {
    $(".modal .flash-messages").html(msg);
  } else {
    $(".flash-messages").html(msg);
  }

  // $(`.${alert_type}`)
  //   .delay(3000)
  //   .slideUp("slow");
};

$(document).on("ajax:complete", function(event) {
  const [request, status] = Array.from(event.detail);
  const msg = request.getResponseHeader("X-Message");
  const type = request.getResponseHeader("X-Message-Type");

  if (msg != null) {
    showAjaxMessage(msg, type);
  }
});
